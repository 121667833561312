<template>
  <div
    :class="[
      'sidebar',
      {
        'sidebar_open': computedModelValue
      }
    ]"
  >
    <div class="sidebar__wrapper">
      <div class="sidebar__inner">
        <router-link
          class="sidebar__logo"
          :to="{
            name: 'AssessmentPlansList'
          }"
        >
          <img src="@/assets/img/svg/full-logo.svg" alt="MainTest" class="sidebar__logo__full"/>
          <img src="@/assets/img/svg/full-logo.svg" alt="MainTest" class="sidebar__logo__mini"/>
        </router-link>

        <div class="sidebar__list">
          <slot name="prepend"/>
          <template
              v-for="(item, itemIndex) in computedMenuLinks"
              :key="itemIndex"
          >
            <div
                v-if="item.accessRoles.includes(authUser?.role?.slug)"
                class="sidebar__list__item"
            >
              <button
                  :class="['sidebar__list__item__header', {
                    'sidebar__list__item__header--active': item.isChildrenOpen || isRouteMatched(item.route.name)
                  }]"
                  @click="toggleChildrenMenu(item.id, item.route)"
              >
                <div class="sidebar__list__item__title">
                  <svg
                      :data-src="item.icon"
                      class="sidebar__list__item__title__icon"
                  />
                  {{ item.title }}
                </div>

                <chevron-bottom-icon
                    v-if="item.children"
                    :class="[
                      'sidebar__list__item__arrow-btn',
                      {
                        'sidebar__list__item__arrow-btn--active': item.isChildrenOpen
                      }
                    ]"
                />

              </button>

              <div
                  v-if="item.children"
                  class="sidebar__list__child"
              >
                <mt-slide-in-out-animation v-model="item.isChildrenOpen">
                  <template v-for="(child, childIndex) in item.children">
                    <router-link
                        v-if="child.accessRoles.includes(authUser?.role?.slug)"
                        :key="childIndex"
                        :to="child.route"
                        :class="['sidebar__list__child__item', {
                          'sidebar__list__child__item--active': isRouteMatched2(child.route.name)
                        }]"
                        @click="closeOnMobile"
                    >
                      {{ child.title }}
                    </router-link>
                  </template>
                </mt-slide-in-out-animation>
              </div>
            </div>
          </template>
          <slot name="append"/>
        </div>
      </div>
      <button
          class="sidebar__open-close"
          @click="openClose()"
      >
        <span class="sidebar__open-close_inner">
          <span class="title">Свернуть меню</span>
        </span>
      </button>
    </div>

    <div
        class="sidebar__overlay"
        @click="openClose"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MtSlideInOutAnimation from "@/components/UI/mtSlideInOutAnimation/index.vue";
import ChevronBottomIcon from "@/components/icons/ChevronBottomIcon.vue";

export default {
  components: {ChevronBottomIcon, MtSlideInOutAnimation },
  props: {
    modelValue: Boolean,
  },
  data() {
    return {
      menuLinks: [
        {
          id: 1,
          title: 'Планы оценок',
          icon: '/img/icons/sidebar/assessment_plans__logo.svg',
          route: {
            name: 'AssessmentPlansList',
          },
          accessRoles: ['admin', 'metodolog', 'operator', 'expert', 'respondent'],
          children: [
            {
              title: 'Список',
              route: {
                name: 'AssessmentPlansList',
              },
              accessRoles: ['admin', 'metodolog', 'operator', 'expert', 'respondent'],
            },
            {
              title: 'Архив',
              route: {
                name: 'AssessmentPlansArchive',
              },
              accessRoles: ['admin', 'metodolog', 'operator', 'expert', 'respondent'],
            }
          ]
        },
        {
          id: 2,
          title: 'Тесты',
          icon: '/img/icons/sidebar/test_library__logo.svg',
          route: {
            name: 'TestLibrary',
          },
          accessRoles: ['admin', 'metodolog', 'operator'],
          children: [
            {
              title: 'Библиотека',
              route: {
                name: 'TestLibraryTestsIndex',
              },
              accessRoles: ['admin', 'metodolog', 'operator']
            },
            {
              title: 'Собственные',
              route: {
                name: '',
              },
              accessRoles: ['admin', 'metodolog', 'operator']
            },
            {
              title: 'Сеансы',
              route: {
                name: 'TestLibrarySessions',
              },
              accessRoles: ['admin', 'metodolog', 'operator']
            },
            {
              title: 'Результаты',
              route: {
                name: 'TestLibraryResults',
              },
              accessRoles: ['admin', 'metodolog', 'operator']
            },
            /*{
              title: 'Корзина',
              route: {
                name: 'TestLibraryTrash',
              },
              accessRoles: ['admin', 'metodolog', 'operator']
            }*/
          ]
        },
        {
          id: 5,
          title: 'Администрирование',
          icon: '/img/icons/sidebar/administration__logo.svg',
          route: {
            name: 'AdminsList',
          },
          accessRoles: ['admin', 'metodolog', 'operator'],
          children: [
            {
              title: 'Администраторы',
              route: {
                name: 'AdminsList',
              },
              accessRoles: ['admin', 'metodolog', 'operator']
            },
            {
              title: 'Пользователи',
              route: {
                name: 'UsersList',
              },
              accessRoles: ['admin', 'metodolog', 'operator']
            },
            {
              title: 'Биллинг',
              route: {
                name: 'Billing',
              },
              accessRoles: ['admin', 'metodolog', 'operator']
            },
            {
              title: 'События',
              route: {
                name: 'Events',
              },
              accessRoles: ['admin', 'metodolog', 'operator']
            },
           /* {
              title: 'Филиалы',
              route: {
                name: 'BranchesList',
              },
              accessRoles: ['admin', 'metodolog', 'operator']
            },*/
            {
              title: 'Настройки',
              route: {
                name: 'Settings',
              },
              accessRoles: ['admin', 'metodolog', 'operator']
            },
          ]
        }
      ],
      openedNavID: null
    };
  },
  computed: {
    ...mapGetters(['authUser']),

    computedModelValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    },

    computedMenuLinks() {
      return this.menuLinks.map(item => {
        item.isChildrenOpen = this.computedModelValue && (+item.id === +this.openedNavID);

        return item;
      })
    }
  },

  methods: {
    toggleChildrenMenu(id, route) {
      const matched = this.isRouteMatched(route.name);
      if(!matched) this.$router.push(route);

      const foundItem = this.computedMenuLinks.find(item => item.id === id);

      if(!foundItem.children) {
        return this.computedMenuLinks.forEach(item => {
          item.isChildrenOpen = false;
        })
      }

      this.computedMenuLinks.forEach(item => {
        if (item.id === foundItem.id && this.computedModelValue) {
          return item.isChildrenOpen = !item.isChildrenOpen;
        }

        item.isChildrenOpen = false;
      })

      this.openedNavID = foundItem.id;
    },

    isRouteMatched(routeName) {
      let result = false;

      this.$route.matched[0].children.find(item => {
        if(item.children && item.children.length) {
          return result = !!item.children.find(i => i.name === routeName)
        }

        result = item.name === routeName
      })

      return result;
    },

    isRouteMatched2(routeName) {
      if(!this.$route.matched[1]?.children.length) return this.$route.matched[1]?.name === routeName;

      const foundItem = this.$route.matched[1].children.find(i => i.name === routeName);

      return !!foundItem;
    },

    openClose() {
      this.computedModelValue = !this.computedModelValue;

      if(!this.computedModelValue) {
        this.computedMenuLinks.forEach(item => item.isChildrenOpen = false);
      }
    },

    closeOnMobile() {
      if(window.innerWidth < 767) {
        this.computedModelValue = false;
      }
    }
  },
  mounted() {
    const sidebarOpenCache = localStorage.getItem('isSidebarOpen');
    this.computedModelValue = sidebarOpenCache ? !!Number(sidebarOpenCache) : true;
    this.openedNavID = localStorage.getItem('openedNavID');
    if (!this.authUser.isMaintestProConnected) {
      const item = this.menuLinks.find((x) => x.title == 'Тесты')?.children.find((x) => x.title == 'Собственные');
      if (item) item.accessRoles = [];
    }
  },
  watch: {
    openedNavID() {
      localStorage.setItem('openedNavID', this.openedNavID)
    },

    computedModelValue() {
      localStorage.setItem('isSidebarOpen', this.computedModelValue ? '1' : '0');
    }
  }
};
</script>

<style lang="scss">
.sidebar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 94px;
  transition: all 0.5s ease;
  z-index: 99;
  overflow: hidden;
  border-right: 1px solid #e5e5e5;

  @include max-md {
    width: 80px;
  }

  &__list {
    &__item {
      &__header {
        overflow: hidden;
        width: 63px;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 10px 0 17px;
        border-radius: 5px;
        transition: box-shadow .3s, padding .3s, width .3s;
        cursor: pointer;

        &--active {
          background-color: rgba(0, 120, 140, 0.1);
          color: $green;

          .sidebar__list__item {
            &__title {
              color: $green;
            }

            &__arrow-btn {
              color: $green;
            }
          }
        }

        @include max-md {
          width: 50px;
          padding: 0 10px;
        }
      }

      &__title {
        display: flex;
        align-items: center;
        color: #c7c7c7;
        white-space: nowrap;
        margin-right: 5px;
        transition: color .3s;

        &__icon {
          min-width: 30px;
          max-width: 30px;
          height: 30px;
          margin-right: 20px;
          transition: margin-right .3s;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          color: $green;
        }
      }

      &__arrow-btn {
        width: 20px;
        height: 20px;
        margin-left: auto;
        transition: transform .3s;
        transform: rotate(0deg);
        &--active {
          transform: rotate(180deg);
        }
      }

      & + & {
        margin-top: 10px;
      }
    }

    &__child {
      padding-left: 55px;

      &__item {
        display: flex;
        width: 100%;
        padding: 5px 0;
        color: #c7c7c7;
        transition: color .3s;

        &:hover {
          color: $green;
        }

        &--active {
          color: $green;
        }
      }
    }
  }

  &__wrapper {
    max-width: 275px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 50px 15px;
    height: 100vh;
    background-color: #fafafa;
    border-right: 1px solid #e5e5e5;

    .sidebar__inner {
      .sidebar__logo {
        display: block;
        margin-bottom: 50px;
        img {
          height: 50px;
        }
      }

      .sidebar__list {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #c7c7c7;

      }
    }

    .sidebar__open-close {
      text-align: left;

      .sidebar__open-close_inner {
        background: url(../../assets/img/svg/sidebar__close-open.svg) no-repeat left center;
        margin-left: 8px;
        &:hover {
          background: url(../../assets/img/svg/sidebar__close-open_active.svg) no-repeat left center;
        }

        .title {
          padding-left: 50px;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          color: #00788c;
        }
      }
    }
  }
  &__logo {
    // margin-left костыль, для выравнивания лого, нужна более гибкая реализация
    transition: margin-left 0.15s;
    margin-left: 6px!important;

    &__full {
      display: none;
      object-fit: contain;
      object-position: left;
      width: 240px!important;
    }
    &__mini {
      display: block;
      width: 50px;
      object-fit: cover;
      object-position: left;
    }
  }
  &_open {
    width: 275px;
    border-right: 0;
    .sidebar__logo {
      margin-left: 0!important;
      &__full {
        display: block!important;
      }
      &__mini {
        display: none!important;
      }
    }
    .sidebar {
      &__list {
        &__item {
          &__header {
            width: 100%;
            padding-left: 10px;
          }

          &__title {
            &__icon {
              margin-right: 5px;
            }
          }
        }
      }

      &__overlay {
        @include max-md {
          opacity: 1;
          visibility: visible;
        }
      }
    }


    .title {
      opacity: 1!important;
    }


    @include max-md {
      width: 100%;
    }
  }

  .title {
    white-space: nowrap;
    opacity: 0;
    transition: all 0.5s ease;
  }

  &__overlay {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    transition: all .3s;
    z-index: -1;
  }

  @include max-sm {
    width: 0;
    border-right: 0;
  }
}

.sub_sidebar {
  padding-left: 60px;
  padding-bottom: 10px;

  overflow: hidden;
  transition: max-height 0.5s ease;
  max-height: 0;

  .sub_sidebar__li {
    padding-top: 10px;

    &:hover {
      color: #00788c;
      cursor: pointer;
    }
  }
}
</style>